import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { flattenToAppURL } from '@plone/volto/helpers';
import { toast } from 'react-toastify';
import { Menu, Tab, Container, Button, Form, Image } from 'semantic-ui-react';
import { messages } from '@plone/volto/helpers';
import { Toast } from '@plone/volto/components';
import { getUser, updateUser, getUserSchema } from '@plone/volto/actions';
import { FileWidget } from '@plone/volto/components';
import { WysiwygWidget } from '@plone/volto/components';
import axios from 'axios';

const tabMessages = defineMessages({
  account: {
    id: 'Account',
    defaultMessage: 'Account',
  },
  contactInfo: {
    id: 'Contact Info',
    defaultMessage: 'Contact Info',
  },
  onlinePresence: {
    id: 'Online Presence',
    defaultMessage: 'Online Presence',
  },
  details: {
    id: 'Details',
    defaultMessage: 'Details',
  },
});

const PersonalInformation = ({ closeMenu }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [practiceAreas, setPracticeAreas] = useState([]);
  const [portraitUrl, setPortraitUrl] = useState('');
  const user = useSelector((state) => state.users.user);
  const userId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : '',
  );
  const loading = useSelector((state) => state.users.update.loading);
  const userschema = useSelector((state) => state.userschema);
  useEffect(() => {
    dispatch(getUser(userId));
    dispatch(getUserSchema());
    if (userId) {
      setPortraitUrl(`/++api++/@portrait/${userId}?${new Date().getTime()}`);
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (user) {
      setFormData({
        ...user,
        email_private: !!user.email_private,
        phone_private: !!user.phone_private,
        site_subscription: !!user.site_subscription,
      });
    }
  }, [user]);

  useEffect(() => {
    const fetchPracticeAreas = async () => {
      try {
        const response = await fetch(
          '++api++/@vocabularies/txbizlaw.plone.legal_practice_areas',
        );
        const data = await response.json();
        setPracticeAreas(
          data.items.map((item) => ({ value: item.token, label: item.title })),
        );
      } catch (error) {
        console.error('Error fetching practice areas:', error);
      }
    };

    fetchPracticeAreas();
  }, []);

  const onSubmit = () => {
    const data = { ...formData };
    const { id, username, roles, ...userData } = data;

    // Ensure portrait data is properly structured if it exists
    if (userData.portrait && typeof userData.portrait === 'object') {
      userData.portrait = {
        'content-type': userData.portrait['content-type'],
        data: userData.portrait.data,
        encoding: userData.portrait.encoding,
        filename: userData.portrait.filename,
      };
    }

    // Ensure text field is properly formatted
    if (userData.text && typeof userData.text === 'object') {
      userData.text = userData.text.data || userData.text;
    }

    // Add more detailed logging
    console.log('Current formData:', formData);
    console.log('Submitting user data:', userData);
    console.log('User ID:', userId);

    // Make a direct API call to update the user
    fetch(`/++api++/@users/${userId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        toast.success(
          <Toast
            success
            title={intl.formatMessage(messages.success)}
            content={intl.formatMessage(messages.saved)}
          />,
        );
        
        // Refresh user data after successful update
        dispatch(getUser(userId)).then((refreshResponse) => {
          console.log('Refresh response:', refreshResponse);
        });

        // If we have a new portrait in the form data, keep showing it
        // Otherwise, force a refresh of the server portrait
        if (!userData.portrait) {
          setPortraitUrl(`/++api++/@portrait/${userId}?${new Date().getTime()}`);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error(
          <Toast
            error
            title={intl.formatMessage(messages.error)}
            content={intl.formatMessage(messages.error)}
          />,
        );
      });

    if (closeMenu) closeMenu();
  };

  const onCancel = () => {
    if (closeMenu) closeMenu();
    else history.goBack();
  };

  const handleInputChange = useCallback((e, data) => {
    const { name, value } = data || e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  const handleFileChange = useCallback(
    (name, file) => {
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result.split(',')[1];
          const dataUrl = reader.result;
          
          // Update form data with the portrait data
          setFormData((prevData) => ({
            ...prevData,
            portrait: {
              'content-type': file.type,
              data: base64data,
              encoding: 'base64',
              filename: file.name,
              preview: dataUrl // Store the preview URL in the form data
            }
          }));
          // Update the preview image immediately
          setPortraitUrl(dataUrl);
        };
        reader.readAsDataURL(file);
      } else {
        // Handle file removal
        setFormData((prevData) => ({
          ...prevData,
          portrait: null
        }));
        // Reset to server portrait URL
        setPortraitUrl(`/++api++/@portrait/${userId}?${new Date().getTime()}`);
      }
    },
    [userId]
  );

  const handleCheckboxChange = (e, { name, checked }) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const fieldInfo = {
    fullname: {
      label: 'Full Name',
      type: 'text',
    },
    email: {
      label: 'Email',
      type: 'email',
    },
    location: {
      label: 'Office Location',
      type: 'text',
    },
    mailing_address: {
      label: 'Business Mailing Address',
      type: 'text',
    },
    phone: {
      label: 'Phone Number',
      type: 'text',
    },
    fax: {
      label: 'Fax Number',
      type: 'text',
    },
    cell: {
      label: 'Cell Number',
      type: 'text',
    },
    email_private: {
      label: 'Make My Email Private',
      type: 'checkbox',
    },
    phone_private: {
      label: 'Make My Phone Private',
      type: 'checkbox',
    },
    site_subscription: {
      label: 'Email Subscription',
      type: 'checkbox',
    },
    home_page: {
      label: 'Website',
      type: 'text',
    },
    linkedin: {
      label: 'LinkedIn Page',
      type: 'text',
    },
    facebook: {
      label: 'Facebook Page',
      type: 'text',
    },
    personal_urls: {
      label: 'Personal URLs',
      type: 'url', // Or handle it differently based on your requirements
    },
    description: {
      label: 'Short Practice Description',
      type: 'textarea', // For multi-line input
    },
    practice_area: {
      label: 'Legal Practice Area',
      type: 'select',
    },
    text: {
      label: 'About You and Your Practice',
      type: 'richtext',
      help: 'Share detailed information about your legal background, expertise, and practice areas. This will help other members understand your professional focus and experience.',
    },
    full_text: {
      label: 'Full Search Text',
      type: 'textarea',
    },
    city_search: {
      label: 'City Search Text',
      type: 'text',
    },
    practice_search: {
      label: 'Practice Search Text',
      type: 'text',
    },
    portrait: {
      label: 'Portrait',
      type: 'file',
    },
  };

  const renderField = (fieldName) => {
    const field = fieldInfo[fieldName];

    if (!field) {
      return null; // or handle the case where the field is not found
    }

    const commonProps = {
      name: fieldName,
      value: formData[fieldName] || '',
      onChange: handleInputChange,
    };

    switch (field.type) {
      case 'text':
        return <Form.Input {...commonProps} type="text" />;

      case 'email':
        return <Form.Input {...commonProps} type="email" />;

      case 'textarea':
        return <Form.TextArea {...commonProps} />;

      case 'checkbox':
        return (
          <Form.Checkbox
            label={field.label}
            name={fieldName}
            checked={!!formData[fieldName]}
            onChange={handleCheckboxChange}
          />
        );

      case 'select':
        if (fieldName === 'practice_area') {
          return (
            <Form.Dropdown
              placeholder="Select practice areas"
              fluid
              multiple
              search
              selection
              clearable
              name="practice_area"
              value={Array.isArray(formData.practice_area) ? formData.practice_area : []}
              options={practiceAreas.map((area) => ({
                key: area.value,
                value: area.value,
                text: area.label,
              }))}
              onChange={(e, { value }) => {
                setFormData((prevData) => ({
                  ...prevData,
                  practice_area: value,
                }));
              }}
            />
          );
        }
        return (
          <Form.Select
            {...commonProps}
            options={[
              { key: '', value: '', text: 'Select a practice area' },
              ...practiceAreas.map((area) => ({
                key: area.value,
                value: area.value,
                text: area.label,
              })),
            ]}
          />
        );

      case 'file':
        return (
          <div>
            <FileWidget
              id="portrait"
              name="portrait"
              value={formData.portrait}
              onChange={(name, value) => handleFileChange(name, value?.[0])}
              accept="image/*"
            />
            {portraitUrl && (
              <Image
                src={formData.portrait?.preview || portraitUrl}
                alt="Current portrait"
                size="small"
                style={{ marginTop: '10px' }}
              />
            )}
          </div>
        );

      case 'richtext':
        return (
          <Form.Field className="about-practice-editor">
            <label>{field.label}</label>
            {field.help && <p className="help-text">{field.help}</p>}
            <WysiwygWidget
              id={fieldName}
              name={fieldName}
              value={formData[fieldName] || ''}
              onChange={(id, value) => {
                console.log('WysiwygWidget onChange:', { id, value }); // For debugging
                // Extract the HTML content from the rich text value
                const htmlContent = value?.data || value;
                setFormData((prevData) => ({
                  ...prevData,
                  [id]: htmlContent,
                }));
              }}
              columns={12}
            />
          </Form.Field>
        );

      default:
        return <Form.Input {...commonProps} type="text" />;
    }
  };

  // Updated renderFormFields function
  const renderFormFields = (fields) => {
    return fields.map((fieldName) => {
      const field = fieldInfo[fieldName];
      // Skip label rendering for richtext fields as they handle their own labels
      const shouldRenderLabel = field?.type !== 'richtext';
      
      return (
        <div key={fieldName}>
          {shouldRenderLabel && <label>{field?.label}</label>}
          {renderField(fieldName)}
        </div>
      );
    });
  };

  const panes = [
    {
      menuItem: (
        <Menu.Item key="account">
          {intl.formatMessage(tabMessages.account)}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Form>
            {renderFormFields(['fullname', 'email', 'description', 'portrait'])}
          </Form>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="contactInfo">
          {intl.formatMessage(tabMessages.contactInfo)}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Form>
            {renderFormFields([
              'location',
              'mailing_address',
              'phone',
              'fax',
              'cell',
              'email_private',
              'phone_private',
            ])}
          </Form>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="onlinePresence">
          {intl.formatMessage(tabMessages.onlinePresence)}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Form>{renderFormFields(['home_page', 'facebook', 'linkedin'])}</Form>
        </Tab.Pane>
      ),
    },
    {
      menuItem: (
        <Menu.Item key="details">
          {intl.formatMessage(tabMessages.details)}
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          <Form>
            {renderFormFields([
              'practice_area',
              'text',
              'full_text',
              'city_search',
              'practice_search',
            ])}
          </Form>
        </Tab.Pane>
      ),
    },
  ];

  return (
    userschema?.loaded && (
      <Container className="personal-information">
        <style>
          {`
            .help-text {
              color: #666;
              font-size: 0.9em;
              margin-bottom: 0.5em;
              font-style: italic;
            }
            .about-practice-editor {
              margin-top: 1em;
              padding: 0;
              width: 100%;
            }
            .about-practice-editor .DraftEditor-root {
              min-height: 300px;
              border: 1px solid #ddd;
              padding: 1em;
              box-sizing: border-box;
              width: 100%;
            }
            .about-practice-editor .public-DraftEditor-content {
              min-height: 300px;
            }
            /* Override grid system for full width */
            .about-practice-editor .grid {
              margin: 0 !important;
            }
            .about-practice-editor .stretched.row {
              padding: 0 !important;
            }
            .about-practice-editor .stretched.row > .column {
              padding: 0 !important;
            }
            /* Hide the wrapper */
            .about-practice-editor .wrapper {
              display: none;
            }
            /* Remove extra padding from form fields */
            .ui.form .field {
              padding: 0;
              margin-bottom: 1em;
            }
            /* Remove padding from tab pane */
            .ui.tab.segment {
              padding-left: 0;
              padding-right: 0;
            }
            /* Ensure toolbar appears properly */
            .draftJsToolbar__toolbar__dNtBH {
              transform: translate(-50%) scale(1) !important;
              visibility: visible !important;
              z-index: 2;
            }
          `}
        </style>
        <Tab panes={panes} />
        <div className="form-actions">
          <Button primary onClick={onSubmit} disabled={loading}>
            {intl.formatMessage(messages.save)}
          </Button>
          <Button onClick={onCancel}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        </div>
      </Container>
    )
  );
};

PersonalInformation.propTypes = {
  closeMenu: PropTypes.func,
};

export default PersonalInformation;
